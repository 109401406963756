import { CogIcon } from '@heroicons/react/solid'

export default function LoadingIndicator(props) {
  return (
    <div className={props.className}>
      <div className="flex gap-2 items-center animate-pulse">
        <CogIcon
          className="animate-spin"
          style={{
            width: props.size || '16px',
            height: props.size || '16px',
          }}
        />
        {props.title && <span style={{fontSize: props.size || '16px'}}>{props.title}</span>}
      </div>
    </div>
  );
}
