import { useAsyncFn } from 'react-use';
import Button from './components/Button';
import Logo from './components/Logo';

export default function App() {
  const [state, doFetch] = useAsyncFn(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(<div className="text-center">
          It seems like you are elegible for the tunnel tour.<br />
          <a
            className="text-blue-500"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          >
            Book it here
          </a>
        </div>);
      }, 1000);
    });
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-white">
      <div className="flex flex-col gap-5 items-center text-gray-600">
        <Logo />

        <div className="text-6xl font-bold">
          welcome
        </div>

        <Button
          color="green"
          onClick={doFetch}
          loadingState={state.loading}
          loadingSpinner={true}
          disabled={!state.loading && !state.error && state.value}
        >
          Book it now!
        </Button>

        <div>{state.value}</div>
        {state.error && <div>{state.error.message}</div>}
      </div>
    </div>
  );
}
