import LoadingIndicator from "./LoadingIndicator";

export default function Button({
  color = "default",
  className,
  children,
  disabled,
  loadingState = false,
  loadingSpinner = false,
  loadingText = null,
  ...buttonProps
}) {
  const sizingClass = "relative inline-flex items-center px-4 py-2";
  const textClass = "shadow-sm text-sm font-medium text-gray-700"
  const borderClass = "border border-gray-300 rounded-md";
  const colorClass = {
    "default": "bg-white hover:bg-gray-50 disabled:bg-gray-50",
    "primary": "bg-blue-200 hover:bg-blue-100 disabled:bg-blue-100",
    "green": "bg-green-200 hover:bg-green-100 disabled:bg-green-100",
    "red": "bg-red-200 hover:bg-red-100 disabled:bg-red-100",
  }[color] || "bg-white hover:bg-gray-50";
  const focusClass = "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
  const disabledClass = "disabled:opacity-50";

  return (
    <button
      className={`${sizingClass} ${textClass} ${borderClass} ${colorClass} ${focusClass} ${disabledClass} ${className}`}
      {...buttonProps}
      disabled={loadingState || disabled}
    >
      <div className={`${loadingState && loadingSpinner ? "opacity-0" : "opacity-100"}`}>
        {children}
      </div>
      <div className={`absolute top-1/2 left-1/2 w-100 h-100 -translate-x-1/2 -translate-y-1/2 ${loadingState && loadingSpinner ? "opacity-100" : "opacity-0"}`}>
        <LoadingIndicator title={loadingText} size="15px" />
      </div>
    </button>
  );
}
